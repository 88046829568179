import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetImageForLanguage } from '../../../../hooks';
import styles from './Card.module.scss';

type CardProps = {
  backgroundColor: string;
  label: string;
  url: string;
  img: string;
};

export const Card = ({ backgroundColor, label, url, img }: CardProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <button
          className={styles.cardImg}
          style={{ backgroundColor }}
          onClick={() => navigate(url)}
        >
          <img
            src={useGetImageForLanguage('en', img)}
            alt="person smiling"
            style={{ width: '100%' }}
          />
        </button>
        <button className={styles.cardButton} onClick={() => navigate(url)}>
          {label}
        </button>
      </div>
    </div>
  );
};
