import { createContext } from 'react';

export type TreatmentPlanContextProps = {
  treatmentPlan: string;
  setTreatmentPlan: (treatmentPlan: string) => void;
};

export const TreatmentPlanContext = createContext<TreatmentPlanContextProps>(
  {} as TreatmentPlanContextProps
);
