import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Anchor.module.scss';
export const Anchor = () => {
  const { t } = useTranslation();

  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const sections = [
      'introduction',
      'symbicort',
      'howToUse',
      'inhaledSteroids',
      'sideEffects',
      'supportTools',
    ];
    let currentSection = '';

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (
        element &&
        element.getBoundingClientRect().top <= window.innerHeight / 2
      ) {
        currentSection = section;
      }
    });

    setActiveSection(currentSection);
  };
  return (
    <div className={styles.navbar} id="my-first-inhaler" data-testid="Anchor">
      <a
        href="#introduction"
        className={activeSection === 'introduction' ? styles.active : ''}
      >
        {t('anchors.introduction')}
      </a>
      <a
        href="#symbicort"
        className={activeSection === 'symbicort' ? styles.active : ''}
      >
        {t('anchors.symbicort')}
      </a>
      <a
        href="#howToUse"
        className={activeSection === 'howToUse' ? styles.active : ''}
      >
        {t('anchors.howToUse')}
      </a>
      <a
        href="#inhaledSteroids"
        className={activeSection === 'inhaledSteroids' ? styles.active : ''}
      >
        {t('anchors.inhaledSteroids')}
      </a>
      <a
        href="#sideEffects"
        className={activeSection === 'sideEffects' ? styles.active : ''}
      >
        {t('anchors.sideEffects')}
      </a>
      <a
        href="#supportTools"
        className={activeSection === 'supportTools' ? styles.active : ''}
      >
        {t('anchors.supportTools')}
      </a>
    </div>
  );
};
