import React, { FC, useEffect, useState } from 'react';
import styles from './SideEffects.module.scss';
import { useTranslation } from 'react-i18next';
import { getUrl } from 'aws-amplify/storage';
import { useSetTreatmentPlan } from '../../../../hooks';
import { useLocation } from 'react-router-dom';
import arrow from '../../../../assets/icons/arrow.png';

type SideEffectsProps = {
  treatmentPlan: any;
};
export const SideEffects: FC<SideEffectsProps> = ({ treatmentPlan }) => {
  const { t } = useTranslation();
  const [linkToLeaflet, setLinkToLeaflet] = useState<any>(null);
  const { pathname } = useLocation();

  const leafletPath = t(`patientInformationLeaflet.leafletPath`);
  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname);

  const { sideEffectTabs } = useSetTreatmentPlan(cleanPathName.split('/')[0]);
  useEffect(() => {
    async function getSignedLeafletUrl() {
      const pdf = await getUrl({
        path: leafletPath,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setLinkToLeaflet(pdf?.url.href);
    }

    getSignedLeafletUrl();
  }, []);
  const [tabId, setTabId] = useState('commonSideEffects');

  const handleSetTab = (tabId: string) => {
    setTabId(tabId);
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={styles.sideEffects} data-testid="SideEffects">
      <section id="sideEffects" className={styles.sideEffectsContainer}>
        <h1>{t(`${treatmentPlan}.sideEffectsTitle`)} </h1>

        <div className={styles.twoBoxes}>
          <div className={styles.flexBasisHalf}>
            <h3>{t(`${treatmentPlan}.sideEffectsStrong`)}</h3>
          </div>
          <br />

          <button className={styles.seeMoreBtn} onClick={toggleCollapse}>
            <img
              src={arrow}
              alt="arrow"
              style={{
                transform: isCollapsed ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            />
            More on side effects
          </button>

          <div
            className={[
              styles.flexBasisHalf,
              isCollapsed ? styles.collapsed : styles.notCollapsed,
            ].join(' ')}
          >
            <p>
              {t(`${treatmentPlan}.sideEffectsParagraph1Leading`)}

              <a href={linkToLeaflet} target="_blank">
                {t(`${treatmentPlan}.sideEffectsParagraphLink`)}
              </a>
              {t(`${treatmentPlan}.sideEffectsParagraph1Trailing`)}
            </p>
            <p>{t(`${treatmentPlan}.sideEffectsParagraph2`)}</p>
            <p>
              <strong>
                {t(`${treatmentPlan}.sideEffectsParagraphStrong`)}
              </strong>
            </p>
          </div>
        </div>

        <div
          className={[
            isCollapsed ? styles.collapsed : styles.notCollapsed,
          ].join(' ')}
        >
          <div className={styles.tab}>
            {sideEffectTabs.map((tab) => (
              <button
                key={tab.title}
                style={tabId == tab.id ? { backgroundColor: 'white' } : {}}
                onClick={() => handleSetTab(tab.id)}
              >
                <strong> {tab.title.split(',')[0]} </strong>
              </button>
            ))}
          </div>
          {sideEffectTabs.map((tab) => (
            <div
              key={tab.title}
              style={
                tabId == tab.id
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      boxShadow: '0px 8px 16px 0px rgba(60, 66, 66, 0.15)',
                    }
                  : {}
              }
              className={styles.tabContent}
            >
              <div className={styles.tabSubtitle}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(tab.title),
                  }}
                />
              </div>

              <ul>
                {tab.list.map((li: any) => (
                  <li key={li} className={styles.tabList}>
                    {li}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
