import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetIcon, useGetImageForLanguage } from '../../../../hooks';
import styles from './Symbicort.module.scss';

type SymbicortProps = {
  treatmentPlan: any;
};

export const Symbicort: FC<SymbicortProps> = ({ treatmentPlan }) => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <section>
      <h1 className={styles.disclaimer}>
        {t(`${treatmentPlan}.informationCaution`)}
      </h1>
      <div id="symbicort" className={styles.symbicort} data-testid="Symbicort">
        <div className={styles.imageSymbicort}>
          <img
            src={useGetImageForLanguage(
              language,
              'myFirstInhaler/symbicort.png'
            )}
            loading="lazy"
            alt="symbicort"
          />
        </div>
        <div className={styles.introducingSymbicort}>
          <h1 className={styles.title}>
            {t(`${treatmentPlan}.symbicortTitle`)}
          </h1>
          <p className={styles.subtitle}>
            {t(`${treatmentPlan}.symbicortInfo`)} <sup>3-5</sup>
          </p>

          <div className={styles.symbicortInfo}>
            <div>
              <img src={useGetIcon('timer.png')} alt="timer" />
            </div>
            <div>
              <h3> {t(`${treatmentPlan}.relieves`)} </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    t(`${treatmentPlan}.relievesParagraph`) + '<sup>3</sup>',
                }}
              />
            </div>
          </div>

          <div className={styles.symbicortInfo}>
            <div>
              <img src={useGetIcon('check.png')} loading="lazy" alt="check" />
            </div>
            <div>
              <h3> {t(`${treatmentPlan}.treats`)}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    t(`${treatmentPlan}.treatsParagraph`) + '<sup>2-4</sup>',
                }}
              />
            </div>
          </div>

          <div className={styles.leftBorder}>
            {t(`${treatmentPlan}.symbicortFooter`)}
            <sup>2-4</sup>
          </div>
        </div>
      </div>
    </section>
  );
};
