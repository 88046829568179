import React, { FC, useEffect, useState } from 'react';
import styles from './HowToUse.module.scss';
import { useTranslation } from 'react-i18next';
import { useGetImageForLanguage } from '../../../../hooks';
import { VideoPlayer } from '../../../../components';
import { getUrl } from 'aws-amplify/storage';

type HowToUseProps = {
  treatmentPlan: any;
};
export const HowToUse: FC<HowToUseProps> = ({ treatmentPlan }) => {
  const { t } = useTranslation();

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    async function getSignedUrl() {
      const link = await getUrl({
        path: videoPath,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setLinkToVideo(link);
    }

    getSignedUrl();
  }, []);

  //GET VIDEO DETAILS
  const videoPath = t(`turbohalerVideo.videoPath`);
  const [linkToVideo, setLinkToVideo] = useState<any>(null);

  //CONFIGURE VIDEO PLAYER
  const playerRef = React.useRef(null);
  const videoPlayerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: useGetImageForLanguage(
      language,
      'myFirstInhaler/videoThumbnail.png'
    ),
    posterText: t(`turbohalerVideo.videoText`),
    sources: [
      {
        src: linkToVideo?.url.href,
        type: 'video/mp4',
      },
    ],
  };
  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
  };

  return (
    <div className={styles.howToUse} data-testid="HowToUse">
      <section id="howToUse" className={styles.howToUseContainer}>
        <h1 className={styles.title}>{t(`${treatmentPlan}.howToUseTitle`)}</h1>

        <p>
          <strong>{t(`${treatmentPlan}.howToUseProperUse`)}</strong>
        </p>

        <p>{t(`${treatmentPlan}.howToUseBreathActivated`)}</p>

        <h1 className={styles.subtitles}>
          {t(`${treatmentPlan}.howToUseHowItWorks`)}
        </h1>

        <div
          style={{
            width: '100%',
            backgroundColor: 'grey',
            height: '31rem',
            margin: '20px 0',
          }}
        ></div>

        <h1 className={styles.subtitles}>
          {t(`${treatmentPlan}.howToUseWatchVideo`)}
        </h1>

        {linkToVideo && (
          <div className={styles.videoPlayer}>
            <VideoPlayer
              options={videoPlayerOptions}
              onReady={handlePlayerReady}
            />
          </div>
        )}
      </section>
    </div>
  );
};
