import React from 'react';
import videojs from 'video.js';
import { useTranslation } from 'react-i18next';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import './VideoPlayer.scss';

export const VideoPlayer = (props: any) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef<Player | null>(null);
  const { options, onReady } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-player', 'video-js');
      if (videoRef.current) {
        (videoRef.current as HTMLDivElement).appendChild(videoElement);
      }

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady?.(player);
      }));
      // add play button text
      const bigPlayButton = player.getChild('bigPlayButton');
      if (bigPlayButton) {
        bigPlayButton.el().innerHTML = t('turbohalerVideo.videoButton');
      }
      //add overlay
      const posterOverlay = document.createElement('div');
      posterOverlay.className = 'vjs-poster-overlay';
      const posterImage = player.getChild('posterImage');
      if (posterImage) {
        posterImage.el().appendChild(posterOverlay);
      }
      //set preview text on top of poster image
      if (
        props.options.posterText !== undefined &&
        props.options.posterText !== 'turbohalerVideo.videoText'
      ) {
        const textDiv = document.createElement('span');
        textDiv.className = 'vjs-poster-text';
        textDiv.innerHTML = props.options.posterText;
        if (posterImage) {
          posterImage.el().appendChild(textDiv);
        }
      } else {
        //re-center play button
        const bigPlayButton = player.getChild('bigPlayButton');
        if (bigPlayButton) {
          bigPlayButton.el().classList.add('vjs-big-play-centered');
        }
      }

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      const bigPlayButton = player.getChild('bigPlayButton');
      if (bigPlayButton && options.autoplay) {
        bigPlayButton.el().innerHTML = '';
      }
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player data-testid="VideoPlayer">
      <div ref={videoRef} />
    </div>
  );
};

export default VideoPlayer;
