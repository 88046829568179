import React, { useReducer } from 'react';
import {
  IProfileProviderProps,
  IProfileState,
} from './utils/context.interfaces';
import { ProfileContext } from './profile.context';
import { ProfileReducer } from './profile.reducer';
import httpApi from '../../api/httpApi';

const INITIAL_STATE: IProfileState = {
  userType: '',
  pridId: '',
  projects: [],
  name: '',
  email: '',
};

export function ProfileProvider({ children }: IProfileProviderProps) {
  const [profileState, profileDispatch] = useReducer(
    ProfileReducer,
    INITIAL_STATE
  );

  const setProfile = async (user: IProfileState): Promise<void> => {
    try {
      const { data } = await httpApi.httpApi.get(`/user/${user.pridId}`);

      const userWithProjects: IProfileState = { ...user, ...data };

      profileDispatch({
        type: 'setProfile',
        payload: userWithProjects,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ProfileContext.Provider value={{ profileState, setProfile }}>
      {children}
    </ProfileContext.Provider>
  );
}
