import axios from 'axios';

//TODO remove this
const httpApiEndOfLife = axios.create({
  //   baseURL: 'https://',
  baseURL: 'https://zb58h15214.execute-api.eu-west-1.amazonaws.com',
});

const httpApi = axios.create({
  //   baseURL: 'http://localhost:3000/deve',
  baseURL: 'https://0qfjutzci3.execute-api.eu-west-1.amazonaws.com/deve',
});

httpApi.interceptors.request.use((config: any) => {
  config.headers = {
    ...config.headers,
    'x-token': localStorage.getItem('token'),
  };

  return config;
});

export default { httpApi, httpApiEndOfLife };
