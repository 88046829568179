import React, { FC } from 'react';
import styles from './Disclaimer.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSetTreatmentPlan } from '../../../../hooks';

type DisclaimerProps = {
  treatmentPlan: any;
};

export const Disclaimer: FC<DisclaimerProps> = ({ treatmentPlan }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname.split('/')[1]); // V2

  const { referencesList } = useSetTreatmentPlan(cleanPathName);

  return (
    <section data-testid="Disclaimer">
      {/* =========== DISCLAIMER ========== */}
      <div className={styles.disclaimer}>
        <h4>{t(`${treatmentPlan}.disclaimerTitle`)}</h4>
        <p>{t(`${treatmentPlan}.disclaimer`)}</p>
      </div>

      {/* =========== REFERENCES ========== */}
      <div className={styles.references}>
        <h4>{t(`${treatmentPlan}.references`)}</h4>
        <ol>
          {referencesList.map((reference) => (
            <li key={reference}>{reference}</li>
          ))}
        </ol>
      </div>
    </section>
  );
};
