import React, { FC } from 'react';
import styles from './MainBanner.module.scss';
import { useGetImageForLanguage } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

type MainBannerProps = {
  themePage: any;
};

export const MainBanner: FC<MainBannerProps> = ({ themePage }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div
      className={styles.mainBanner}
      style={{ background: themePage.background }}
      data-testid="MainBanner"
    >
      <img
        src={useGetImageForLanguage(language, themePage.mainImage)}
        loading="lazy"
        alt="person"
      />
    </div>
  );
};
