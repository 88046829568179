import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetImageForLanguage } from '../../../../hooks';
import styles from './InhaledSteroids.module.scss';

type SymbicortProps = {
  treatmentPlan: any;
  themePage: any;
};

export const InhaledSteroids: FC<SymbicortProps> = ({
  treatmentPlan,
  themePage,
}) => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  let list: string[] = [];
  if (treatmentPlan) {
    list = t(`${treatmentPlan}.inhaledSteroidsParagraphList`, {
      returnObjects: true,
    }) as string[];
  }

  return (
    <div
      data-testid="InhaledSteroids"
      className={styles.inhaledSteroids}
      style={{ backgroundColor: themePage.backgroundColor }}
    >
      <section id="inhaledSteroids">
        <h1>{t(`${treatmentPlan}.inhaledSteroidsTitle`)}</h1>

        <div
          style={{
            width: '100%',
            backgroundColor: 'grey',
            height: '31rem',
            margin: '20px 0',
          }}
        ></div>
        <div className={styles.bgImage}>
          <img
            src={useGetImageForLanguage(
              language,
              'myFirstInhaler/grabItAndGo.png'
            )}
            alt="Grab It and Go"
          />
        </div>

        <div className={styles.bgImageTwo}>
          <img
            src={useGetImageForLanguage(
              language,
              'myFirstInhaler/briefCase.png'
            )}
            alt="Briefcase"
          />
        </div>
      </section>
    </div>
  );
};
