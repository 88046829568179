import React, { FC } from 'react';
import styles from './Loading.module.scss';

type LoadingProps = {
  className: string;
};

export const Loading: FC<LoadingProps> = ({ className }) => {
  const classes: any = {
    loadingSmallBar: styles.loadingSmallBar,
    loading: styles.loading,
    modalLoading: styles.modalLoading,
  };

  return <div data-testid="loading" className={`${classes[className]} `}></div>;
};
