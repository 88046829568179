import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetIcon } from '../../hooks';
import styles from './Footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation();
  const icon = useGetIcon('astrazeneca_logo.png');

  return (
    <div className={styles.footerContainer} data-testid="Footer">
      <div className={styles.footerInfo}>
        <img src={icon} alt="AstraZeneca Logo" className={styles.footerLogo} />
        <p>
          © AstraZeneca {new Date().getFullYear()}
          <br />
          {t('footer.dateOfPreparation')} December 2024 | Z4-67641
        </p>
      </div>
      <div className={styles.links}>
        <a
          href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html"
          target="_blank"
        >
          {t('footer.termsOfUse')}
        </a>
        <a href="https://www.azprivacy.astrazeneca.com/" target="_blank">
          {t('footer.privacyNotice')}
        </a>
      </div>
    </div>
  );
};
