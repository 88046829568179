import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetIcon } from '../../../../hooks';
import styles from './Introduction.module.scss';

type IntroductionProps = {
  themePage: any;
  treatmentPlan: any;
};

export const Introduction: FC<IntroductionProps> = ({
  themePage,
  treatmentPlan,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.introduction}
      style={{ backgroundColor: themePage.backgroundColor }}
      data-testid="Introduction"
    >
      <section id="introduction">
        <small>{t(`${treatmentPlan}.introductionPreTitle`)}</small>
        <h1>{t(`${treatmentPlan}.introductionTitle`)}</h1>

        <div className={styles.twoBoxes}>
          <div className={styles.flexBasisHalf}>
            <div>
              <img src={useGetIcon('lungs.png')} loading="lazy" alt="lungs" />
            </div>
            <div>
              <h3>{t(`${treatmentPlan}.majorUpdateAsthmaTreatment`)}</h3>
              <p>
                {t(`${treatmentPlan}.majorUpdateAsthmaTreatmentParagraph`)}
                <sup>1-2</sup>
              </p>
            </div>
          </div>
          <div className={styles.flexBasisHalf}>
            <div>
              <img src={useGetIcon('check.png')} loading="lazy" alt="check" />
            </div>
            <div>
              <h3>{t(`${treatmentPlan}.whyUseAnAIR`)}</h3>
              <p>{t(`${treatmentPlan}.whyUseAnAIRParagraph`)}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
