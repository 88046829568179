import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TreatmentPlanContext } from '../../../context/treatmentPlan/treatmentPlan.context';
import { ETreatmentPlan } from '../../../models/treatmentPlans.enum';
import './BurgerMenu.scss';

export const BurgerMenu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');
  const { treatmentPlan } = useContext(TreatmentPlanContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (treatmentPlan === ETreatmentPlan.MY_FIRST_INHALER) {
      setBackgroundColor('#FFEFA9');
    } else if (treatmentPlan === ETreatmentPlan.ONLY_RELIEVER) {
      setBackgroundColor('#FEBBD7');
    } else if (treatmentPlan === ETreatmentPlan.RELIEVER_AND_PREVENTER) {
      setBackgroundColor('#FFE4E5');
    } else if (treatmentPlan === ETreatmentPlan.SOMETHING_ELSE) {
      setBackgroundColor('#DAFFC9');
    }
  }, [treatmentPlan]);

  return (
    <>
      <div
        className={`burger-icon ${isOpen ? 'open' : ''}`}
        onClick={toggleMenu}
        data-testid="BurgerMenu"
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault(); // Prevent default scrolling behavior for Space key
            toggleMenu();
          }
        }}
      >
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
      </div>
      <nav
        className={`menu ${isOpen ? 'open' : ''}`}
        style={{ backgroundColor }}
      >
        <ul>
          <li>
            <a href="#introduction" onClick={toggleMenu}>
              {t('anchors.introduction')}
            </a>
          </li>
          <li>
            <a href="#symbicort" onClick={toggleMenu}>
              {t('anchors.symbicort')}
            </a>
          </li>
          <li>
            <a href="#howToUse" onClick={toggleMenu}>
              {t('anchors.howToUse')}
            </a>
          </li>
          <li>
            <a href="#inhaledSteroids" onClick={toggleMenu}>
              {t('anchors.inhaledSteroids')}
            </a>
          </li>

          <li>
            <a href="#sideEffects" onClick={toggleMenu}>
              {t('anchors.sideEffects')}
            </a>
          </li>

          <li>
            <a href="#supportTools" onClick={toggleMenu}>
              {t('anchors.supportTools')}
            </a>
          </li>
        </ul>
      </nav>
      <div
        data-testid="BurgerMenuToggle"
        className={`overlay ${isOpen ? 'show' : ''}`}
        onClick={toggleMenu}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault(); // Prevent default scrolling behavior for Space key
            toggleMenu();
          }
        }}
      ></div>
    </>
  );
};
