import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetImageForLanguage } from '../../../../hooks';
import styles from './InhaledSteroids.module.scss';

type SymbicortProps = {
  treatmentPlan: any;
  themePage: any;
};

export const InhaledSteroids: FC<SymbicortProps> = ({
  treatmentPlan,
  themePage,
}) => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  let list: string[] = [];
  if (treatmentPlan) {
    list = t(`${treatmentPlan}.inhaledSteroidsParagraphList`, {
      returnObjects: true,
    }) as string[];
  }

  return (
    <div
      data-testid="InhaledSteroids"
      className={styles.inhaledSteroids}
      style={{ backgroundColor: themePage.backgroundColor }}
    >
      <section id="inhaledSteroids">
        <small>{t(`${treatmentPlan}.inhaledSteroidsPreTitle`)}</small>
        <h1>{t(`${treatmentPlan}.inhaledSteroidsTitle`)}</h1>

        <div className={styles.steroids}>
          <div className={styles.left}>
            <h4
              dangerouslySetInnerHTML={{
                __html: t(`${treatmentPlan}.inhaledSteroidsStrong`),
              }}
            ></h4>
            <div>
              <p>{t(`${treatmentPlan}.inhaledSteroidsParagraph1`)}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`${treatmentPlan}.inhaledSteroidsParagraph2`),
                }}
              ></p>
            </div>
          </div>

          <div className={styles.right}>
            <p
              dangerouslySetInnerHTML={{
                __html: t(`${treatmentPlan}.inhaledSteroidsParagraph3`),
              }}
            ></p>

            {list.length > 0 && (
              <ul>
                {list.map((item) => (
                  <li key={item}>{item} </li>
                ))}
              </ul>
            )}
            <p>{t(`${treatmentPlan}.inhaledSteroidsParagraph4`)}</p>
          </div>
        </div>
        <div className={styles.bgImage}>
          <img
            src={useGetImageForLanguage(
              language,
              'myFirstInhaler/grabItAndGo.png'
            )}
            alt="Grab It and Go"
          />
        </div>

        <div className={styles.bgImageTwo}>
          <img
            src={useGetImageForLanguage(
              language,
              'myFirstInhaler/briefCase.png'
            )}
            alt="Briefcase"
          />
        </div>
      </section>
    </div>
  );
};
