import { createContext } from 'react';
import { IProfileState } from './utils/context.interfaces';

export type ProfileContextProps = {
  profileState: IProfileState;
  setProfile: (user: IProfileState) => Promise<void>;
};

export const ProfileContext = createContext<ProfileContextProps>(
  {} as ProfileContextProps
);
