import React, { FC, ReactNode, useEffect } from 'react';
import cancel from '../../assets/icons/cancel.svg';
import styles from './Modal.module.scss';

interface ModalProps {
  handleClose: () => void;
  show: boolean;
  children: ReactNode;
  height?: string;
  width?: string;
}

export const Modal: FC<ModalProps> = ({
  handleClose,
  show,
  children,
  height,
  width,
}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }

    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [show]);

  const showHideClassName = show
    ? [styles.modal, styles.displayModal]
    : [styles.modal, styles.displayNone];

  // Function to close the modal if clicked outside the modal content
  const handleClickOutside = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).classList.contains(styles.modal)) {
      handleClose();
    }
  };

  return (
    <div
      className={`${showHideClassName.join(' ')} `}
      data-testid='Modal'
      onClick={handleClickOutside}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      }}
    >
      <div
        className={styles.modalMain}
        style={{ minHeight: height, minWidth: width }}
        onClick={(e) => e.stopPropagation()} // Prevents the modal from closing if its content is clicked
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation(); // Prevents the modal from closing if Enter or Space is pressed
          }
        }}
      >
        <div className={styles.modalHeader}>
          <button onClick={handleClose} className={styles.closeBtn}>
            <img src={cancel} alt="close" />
          </button>
        </div>
        <div className={styles.modalBody}>{children}</div>
        <div className={styles.modalFooter}>
          {/* <button className={styles.closeBtn} type="button" onClick={handleClose}>Close</button> */}
        </div>
      </div>
    </div>
  );
};
