import { SetProfileAction } from './utils/actions';
import { types } from './utils/types';
import { IProfileState } from './utils/context.interfaces';

export const ProfileReducer = (
  state: IProfileState,
  action: SetProfileAction
): IProfileState => {
  switch (action.type) {
    case types.setProfile: {
      return {
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
