import React from 'react';

import AppRouter from './config/router/AppRouter';
import { ProfileProvider } from './context/profile/profile.provider';
import { TreatmentPlanProvider } from './context/treatmentPlan/treatmentPlan.provider';

function App() {
  return (
      <ProfileProvider>
        <TreatmentPlanProvider>
          <AppRouter />
        </TreatmentPlanProvider>
      </ProfileProvider>
  );
}
export default App;
