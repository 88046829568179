import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../locales/en.json';
import esTranslation from '../locales/es.json';
import v2Translation from '../locales/v2.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  'en-US': {
    translation: enTranslation, // You can duplicate or adjust based on the full language code
  },
  'es-MX': {
    translation: esTranslation, // Same as above for Spanish
  },
  v2: {
    translation: v2Translation,
  },
};

// Detect the user's preferred language, including the country code
const browserLanguage = navigator.language; // Get full language code (e.g., "en-US")
const supportedLanguages = [
  'en',
  'es',
  'es-419',
  'en-US',
  'es-MX',
  'es-AR',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-HN',
  'es-PE',
  'es-ES',
  'es-US',
  'es-UY',
  'es-VE',
]; // Add more languages as needed
const defaultLanguage = supportedLanguages.includes(browserLanguage)
  ? browserLanguage
  : 'en';

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage, // Set the default language to the full code
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;
