export enum EnumSpanishCountriesCode {
  'Estados Unidos' = 'en-US',
  'México' = 'es-MX',
  'Argentina' = 'es-AR',
  'Colombia' = 'es-CO',
  'Costa Rica' = 'es-CR',
  'España' = 'es-ES',
  'Honduras' = 'es-HN',
  'Perú' = 'es-PE',
  'Uruguay' = 'es-UY',
  'Venezuela' = 'es-VE',
}

export enum EnumEnglishCountriesCode {
  'United States' = 'en-US',
  'Mexico' = 'es-MX',
  'Argentina' = 'es-AR',
  'Colombia' = 'es-CO',
  'Costa Rica' = 'es-CR',
  'Spain' = 'es-ES',
  'Honduras' = 'es-HN',
  'Peru' = 'es-PE',
  'Uruguay' = 'es-UY',
  'Venezuela' = 'es-VE',
}
