export const useGetImageForLanguage = (lang: string, imgPath: string) => {
  switch (lang) {
    case 'es':
      return new URL(`../assets/es/${imgPath}`, import.meta.url).href;
    case 'en':
      return new URL(`../assets/en/${imgPath}`, import.meta.url).href;
    default:
      return new URL(`../assets/en/${imgPath}`, import.meta.url).href;
  }
};
