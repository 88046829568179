import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetIcon } from '../../../../hooks';
import styles from './Introduction.module.scss';

type IntroductionProps = {
  themePage: any;
  treatmentPlan: any;
};

export const Introduction: FC<IntroductionProps> = ({
  themePage,
  treatmentPlan,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.introduction}
      style={{ backgroundColor: themePage.backgroundColor }}
      data-testid="Introduction"
    >
      <section id="introduction">
        <div className={styles.lungsImage}>
          <img src={useGetIcon('lungs.png')} loading="lazy" alt="lungs" />
        </div>
        <h1>{t(`${treatmentPlan}.introductionTitle`)}</h1>

        <div className={styles.infoContainer}>
          <div className={styles.symbicortInfo}>
            <div>
              <img src={useGetIcon('check.png')} alt="timer" />
            </div>
            <div>
              <h3> {t(`${treatmentPlan}.preventer`)} </h3>
              <p>{t(`${treatmentPlan}.preventerParagraph`)}</p>
            </div>
          </div>

          <div className={styles.symbicortInfo}>
            <div>
              <img src={useGetIcon('timer.png')} loading="lazy" alt="check" />
            </div>
            <div>
              <h3> {t(`${treatmentPlan}.reliever`)}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`${treatmentPlan}.relieverParagraph`),
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
