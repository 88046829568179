import { ITreatmentPlanState } from './utils/context.interfaces';
import { types } from './utils/types';

export const TreatmentPlanReducer = (
  state: ITreatmentPlanState,
  action: any
): ITreatmentPlanState => {
  switch (action.type) {
    case types.setTreatmentPlan: {
      return {
        ...state,
        treatmentPlan: action.payload,
      };
    }

    default:
      return state;
  }
};
