import React, { useReducer } from 'react';
import {
  ITreatmentPlansProviderProps,
  ITreatmentPlanState,
} from './utils/context.interfaces';
import { TreatmentPlanContext } from './treatmentPlan.context';
import { TreatmentPlanReducer } from './treatmentPlan.reducer';
import { types } from './utils/types';

const INITIAL_STATE: ITreatmentPlanState = {
  treatmentPlan: '',
};

export function TreatmentPlanProvider({
  children,
}: ITreatmentPlansProviderProps) {
  const [treatmentPlanState, treatmentPlanDispatch] = useReducer(
    TreatmentPlanReducer,
    INITIAL_STATE
  );

  const setTreatmentPlan = (treatmentPlan: string) => {
    treatmentPlanDispatch({
      type: types.setTreatmentPlan,
      payload: treatmentPlan,
    });
  };

  return (
    <TreatmentPlanContext.Provider
      value={{
        treatmentPlan: treatmentPlanState.treatmentPlan,
        setTreatmentPlan,
      }}
    >
      {children}
    </TreatmentPlanContext.Provider>
  );
}
