import React from 'react';
import styles from './Blocked.module.scss';

export const Blocked = () => {
  return (
    <div className={styles.blocked} data-testid="blocked">
      <h1>Your country does not belong to this Unit Business</h1>
    </div>
  );
};
