import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import './config/i18n/i18n';
import App from './App';
import AmplifyConfig from '../amplifyconfiguration.json';
import './index.scss';

Amplify.configure(AmplifyConfig);

const isLocal = import.meta.env.MODE === 'localhost';

if (!isLocal) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE, // .MODE is define in the webClient's package.json as --mode
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
);
